<template>
  <div style="display: contents">
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 15" height="16" width="16" style="color: #20D489">
      <rect xmlns="http://www.w3.org/2000/svg" y="6" width="16" height="3" rx="1.5" fill="currentColor"></rect>
      <rect xmlns="http://www.w3.org/2000/svg" opacity="0.3" y="12" width="8" height="3" rx="1.5" fill="currentColor"></rect>
      <rect xmlns="http://www.w3.org/2000/svg" opacity="0.3" width="12" height="3" rx="1.5" fill="currentColor"></rect>
    </svg>
  </div>
</template>

<script>
export default {}
</script>

<style scoped>

</style>