<template>
  <div id="app">
    <v-app>

      <Navigation v-if="isLoggedIn"/>

      <v-main>

        <ErrorMessage/>
        <SuccessMessage/>
        <WarningMessage/>

        <router-view/>

        <v-btn v-show="fab"
               v-scroll="onScroll"
               bottom
               color="primary"
               dark
               fab
               fixed
               right
               @click="toTop">
          <v-icon>mdi-chevron-up</v-icon>
        </v-btn>
      </v-main>

      <Footer v-if="isLoggedIn"/>
    </v-app>
  </div>
</template>
<script>
import Navigation from "@/components/navigation/Main.vue";
import ErrorMessage from "@/components/messages/ErrorMessage.vue";
import SuccessMessage from "@/components/messages/SuccessMessage";
import Footer from "@/components/navigation/Footer";
import {HTTP} from "@/http-common";
import WarningMessage from "@/components/messages/WarningMessage.vue";

export default {
    components: {WarningMessage, Footer, SuccessMessage, ErrorMessage, Navigation},
    data() {
        return {
            route: '',
            fab: false,
            isLoggedIn: this.$store.state.isLoggedIn,
        }
    },
    methods: {
        onScroll(e) {
            if (typeof window === 'undefined') return
            const top = window.pageYOffset || e.target.scrollTop || 0
            this.fab = top > 20
        },
        toTop() {
            this.$vuetify.goTo(0)
        },
        getRoles() {
            HTTP.get('/roles').then(res => {
                this.$store.commit('roles', res.data.data)
            }).catch(err => {
                console.log(err)
            })
        },
        me() {
            HTTP.get('/me').then(res => {
                let user = res.data.data
                this.$store.dispatch('UpdateUserInfo', {
                    name: user.name,
                    email: user.email,
                    phone: user.phone,
                })
                this.$store.dispatch('UpdateUserProfile', user.photo)
            }).catch(err => {
                console.log(err)
            })
        },
    },
    created() {
        if (this.$store.state.isLoggedIn) {
            this.getRoles()
            this.me()
        }
        console.log(
            "%cWelcome to SPIN TO WIN",
            "color:#23b7e5;font-family:system-ui;font-size:3rem;-webkit-text-stroke: 1px black;font-weight:bold"
        );
    },
}
</script>

