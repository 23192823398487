<template>
  <div>
    <div v-if="!miniVariant" class="text-center mt-5">
      <svg version="1.1" viewBox="0 150 550 168"
           width="200"
           xmlns="http://www.w3.org/2000/svg">
        <g fill="#FFFFFF" fill-rule="none" font-family="none" font-size="none" font-weight="none" stroke="none"
           stroke-dasharray="" stroke-dashoffset="0" stroke-linecap="butt" stroke-linejoin="miter"
           stroke-miterlimit="10"
           stroke-width="1" style="mix-blend-mode: normal" text-anchor="none">
          <g data-paper-data="{&quot;isGlobalGroup&quot;:true,&quot;bounds&quot;:{&quot;x&quot;:65.00000000000003,&quot;y&quot;:172.85827773926306,&quot;width&quot;:420,&quot;height&quot;:124.28344452147391}}">
            <g data-paper-data="{&quot;isPrimaryText&quot;:true}" fill-rule="nonzero">
              <path
                  d="M253.79804,203.24694h-16.4719l16.27344,46.73653c2.97685,8.63286 11.9074,16.07499 21.23486,16.07499h9.92283v-15.57884h-9.92283c-3.8699,0 -5.35833,-2.28225 -6.6483,-5.75524z"
                  data-paper-data="{&quot;glyphName&quot;:&quot;l&quot;,&quot;glyphIndex&quot;:0,&quot;firstGlyphOfWord&quot;:true,&quot;word&quot;:1}"
                  fill="#FFFFFF"></path>
              <path
                  d="M318.4949,201.85774c-18.15878,0 -32.34843,15.18193 -32.34843,33.14226c0,17.8611 14.18965,33.14226 32.34843,33.14226c18.15878,0 32.2492,-15.28116 32.2492,-33.14226c0,-17.96032 -14.09042,-33.14226 -32.2492,-33.14226zM318.4949,252.46418c-9.62515,0 -16.76958,-8.23595 -16.76958,-17.46418c0,-9.32746 7.24367,-17.56341 16.76958,-17.56341c9.52592,0 16.67036,8.23595 16.67036,17.56341c0,9.22823 -7.14444,17.46418 -16.67036,17.46418z"
                  data-paper-data="{&quot;glyphName&quot;:&quot;o&quot;,&quot;glyphIndex&quot;:1,&quot;word&quot;:1}"></path>
              <path
                  d="M385.57323,203.24694c-17.06727,0 -29.86772,14.78502 -29.86772,31.45537c0,16.57113 12.80045,31.35615 29.86772,31.35615h29.86772v-39.29441h-30.36386v15.7773h14.68579v7.73981h-14.18965c-8.33518,0 -14.09042,-7.64058 -14.09042,-15.57884c0,-7.93826 5.75524,-15.67807 14.09042,-15.67807h29.86772v-15.7773z"
                  data-paper-data="{&quot;glyphName&quot;:&quot;g&quot;,&quot;glyphIndex&quot;:2,&quot;word&quot;:1}"
                  fill="#FFFFFF"></path>
              <path
                  d="M452.7508,201.85774c-18.15878,0 -32.34843,15.18193 -32.34843,33.14226c0,17.8611 14.18965,33.14226 32.34843,33.14226c18.15878,0 32.2492,-15.28116 32.2492,-33.14226c0,-17.96032 -14.09042,-33.14226 -32.2492,-33.14226zM452.7508,252.46418c-9.62515,0 -16.76958,-8.23595 -16.76958,-17.46418c0,-9.32746 7.24367,-17.56341 16.76958,-17.56341c9.52592,0 16.67036,8.23595 16.67036,17.56341c0,9.22823 -7.14444,17.46418 -16.67036,17.46418z"
                  data-paper-data="{&quot;glyphName&quot;:&quot;o&quot;,&quot;glyphIndex&quot;:3,&quot;lastGlyphOfWord&quot;:true,&quot;word&quot;:1}"></path>
            </g>
            <g data-paper-data="{&quot;fillRule&quot;:&quot;evenodd&quot;,&quot;fillRuleOriginal&quot;:&quot;evenodd&quot;,&quot;isIcon&quot;:true,&quot;iconStyle&quot;:&quot;standalone&quot;,&quot;selectedEffects&quot;:{&quot;container&quot;:&quot;&quot;,&quot;transformation&quot;:&quot;&quot;,&quot;pattern&quot;:&quot;&quot;},&quot;bounds&quot;:{&quot;x&quot;:65.00000000000003,&quot;y&quot;:172.85827773926306,&quot;width&quot;:132.0511710490479,&quot;height&quot;:124.28344452147391},&quot;iconType&quot;:&quot;icon&quot;,&quot;rawIconId&quot;:&quot;2957895&quot;,&quot;isDetailed&quot;:false,&quot;suitableAsStandaloneIcon&quot;:true}"
               fill-rule="evenodd">
              <path
                  d="M65,215.57683v0.00776c0.00122,1.26517 0.6097,2.52975 1.82542,3.28963l18.47318,11.54574l39.55165,-24.71979c3.77821,-2.36139 8.57245,-2.36139 12.35067,0l39.55165,24.71979l18.47318,-11.54574c1.21448,-0.7591 1.82289,-2.02193 1.82542,-3.28593v-0.01515c-0.00252,-1.264 -0.61093,-2.52684 -1.82542,-3.28593l-62.14173,-38.83857c-1.25934,-0.78714 -2.85755,-0.78714 -4.11689,0l-62.14172,38.83857c-1.21572,0.75988 -1.82419,2.02446 -1.82542,3.28963zM197.05116,254.41172c-0.00252,-1.264 -0.61093,-2.52684 -1.82542,-3.28593l-18.47318,-11.54574l-39.55165,24.71979c-3.77821,2.36139 -8.57245,2.36139 -12.35067,0l-39.55165,-24.71979l-18.47318,11.54574c-1.21572,0.75988 -1.82419,2.02446 -1.82542,3.28963v0.00776c0.00122,1.26517 0.6097,2.52975 1.82542,3.28963l62.14172,38.83858c0.62977,0.39363 1.34401,0.59035 2.05845,0.59035c0.71444,0 1.42867,-0.19672 2.05845,-0.59035l62.14173,-38.83858c1.21448,-0.7591 1.82289,-2.02193 1.82542,-3.28593zM133.08403,257.7128l36.34048,-22.7128l-36.34048,-22.7128c-1.25934,-0.78706 -2.85755,-0.78706 -4.11689,0l-36.34048,22.7128l36.34048,22.7128c1.25934,0.78706 2.85755,0.78706 4.11689,0z"
                  data-paper-data="{&quot;isPathIcon&quot;:true}"
                  fill="#20D489"></path>
            </g>
          </g>
        </g>
      </svg>
    </div>

    <div v-else class="text-center mt-5">
      <svg class="svg-standalone-icon"
           viewBox="65.00000000000003 172.85827773926306 132.0511710490479 124.28344452147391"
           width="60%">
        <g data-paper-data="{&quot;fillRule&quot;:&quot;evenodd&quot;,&quot;fillRuleOriginal&quot;:&quot;evenodd&quot;,&quot;isIcon&quot;:true,&quot;iconStyle&quot;:&quot;standalone&quot;,&quot;selectedEffects&quot;:{&quot;container&quot;:&quot;&quot;,&quot;transformation&quot;:&quot;&quot;,&quot;pattern&quot;:&quot;&quot;},&quot;bounds&quot;:{&quot;x&quot;:65.00000000000003,&quot;y&quot;:172.85827773926306,&quot;width&quot;:132.0511710490479,&quot;height&quot;:124.28344452147391},&quot;iconType&quot;:&quot;icon&quot;,&quot;rawIconId&quot;:&quot;2957895&quot;,&quot;isDetailed&quot;:false,&quot;suitableAsStandaloneIcon&quot;:true}"
           fill-rule="evenodd">
          <path
              d="M65,215.57683v0.00776c0.00122,1.26517 0.6097,2.52975 1.82542,3.28963l18.47318,11.54574l39.55165,-24.71979c3.77821,-2.36139 8.57245,-2.36139 12.35067,0l39.55165,24.71979l18.47318,-11.54574c1.21448,-0.7591 1.82289,-2.02193 1.82542,-3.28593v-0.01515c-0.00252,-1.264 -0.61093,-2.52684 -1.82542,-3.28593l-62.14173,-38.83857c-1.25934,-0.78714 -2.85755,-0.78714 -4.11689,0l-62.14172,38.83857c-1.21572,0.75988 -1.82419,2.02446 -1.82542,3.28963zM197.05116,254.41172c-0.00252,-1.264 -0.61093,-2.52684 -1.82542,-3.28593l-18.47318,-11.54574l-39.55165,24.71979c-3.77821,2.36139 -8.57245,2.36139 -12.35067,0l-39.55165,-24.71979l-18.47318,11.54574c-1.21572,0.75988 -1.82419,2.02446 -1.82542,3.28963v0.00776c0.00122,1.26517 0.6097,2.52975 1.82542,3.28963l62.14172,38.83858c0.62977,0.39363 1.34401,0.59035 2.05845,0.59035c0.71444,0 1.42867,-0.19672 2.05845,-0.59035l62.14173,-38.83858c1.21448,-0.7591 1.82289,-2.02193 1.82542,-3.28593zM133.08403,257.7128l36.34048,-22.7128l-36.34048,-22.7128c-1.25934,-0.78706 -2.85755,-0.78706 -4.11689,0l-36.34048,22.7128l36.34048,22.7128c1.25934,0.78706 2.85755,0.78706 4.11689,0z"
              data-paper-data="{&quot;isPathIcon&quot;:true}" fill="#20D489"></path>
        </g>
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  props: ['miniVariant']
}
</script>

<style scoped>

</style>