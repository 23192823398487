<template>
  <div style="display: contents">
    <svg class="duoicon duoicon-box-2" height="66" viewBox="0 0 24 24" width="66"
         xmlns="http://www.w3.org/2000/svg">
      <path class="duoicon-secondary-layer"
            d="m20.765 7.982.022.19.007.194v7.268a2.5 2.5 0 0 1-1.099 2.07l-.15.095-6.295 3.634-.124.067-.126.06v-8.99l7.765-4.588Z"
            fill="currentColor" opacity=".3"></path>
      <path class="duoicon-primary-layer"
            d="m13.25 2.567 6.294 3.634c.05.03.1.06.148.092L12 10.838 4.308 6.293a2.81 2.81 0 0 1 .148-.092l6.294-3.634a2.498 2.498 0 0 1 2.5 0Z"
            fill="currentColor"></path>
      <path class="duoicon-secondary-layer"
            d="M3.235 7.982 11 12.571v8.988a2.339 2.339 0 0 1-.25-.126l-6.294-3.634a2.502 2.502 0 0 1-1.25-2.165V8.366c0-.13.01-.258.03-.384h-.001Z"
            fill="currentColor" opacity=".3"></path>
    </svg>
  </div>
</template>

<script>
export default {}
</script>

<style scoped>

</style>