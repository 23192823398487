<template>
  <div>
    <AsideLogo :mini-variant="miniVariant"/>
    <Items :mini-variant="miniVariant"/>
  </div>
</template>

<script>


import Items from "@/components/navigation/sidebar/Items.vue";
import AsideLogo from "@/components/navigation/sidebar/AsideLogo.vue";


export default {
  components: {AsideLogo, Items},
  props: ['miniVariant'],
}
</script>

<style>

.sidebar .v-list-item__title, .btn-add .v-list-item__title {
  font-size: 0.88rem;
  font-weight: 500
}

.sidebar.v-list--nav .v-list-item {
  border-radius: 8px;
  transition: 0.3s;
}

.sidebar.v-list--nav .v-list-item:hover {
  border-radius: 8px;
  background-color: rgba(32, 212, 137, 0.4);
}

.sidebar .v-list-item:hover .v-list-item__title, .sidebar .v-list-item:hover .v-list-item__avatar {
  color: #20D489;
}

.sidebar .theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) {
  color: #d3d0df;
}

.sidebar .item--active {
  background-color: rgba(32, 212, 137, 0.4);
}

.btn-grad {
  background-color: #20D489;
  text-transform: uppercase;
}
</style>